<template>
  <div v-if="content">
    <v-btn @click="handleGetData()" block> refresh</v-btn>
    <v-data-table
      :headers="headers"
      :items="content"
      :itemsPerPage="100"
      item-key="ID"
      class="page__table"
      hide-default-footer
    >
      <template v-slot:body="props">
        <template-content
          :items="props.items"
          :onSort="handleSort"
        />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import axios from 'axios'
import TemplateContent from './TemplateContent'
export default {
  components: {
    TemplateContent,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    enterpriseID: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    count: null,
    content: null,
  }),
  computed: {
    headers () {
      return [
        { text: '', sortable: false, },
        { text: 'Title', value: 'Title',  sortable: false, align: 'center', },
        { text: 'ORD', value: 'ORD',  sortable: false, align: 'center', },
        { text: 'ID', value: 'ID',  sortable: false, align: 'center', },
        { text: '', sortable: false, },
      ]
    }
  },
  watch: {
    enterpriseID () {
      this.handleGetData ()
    },
  },
  mounted () {
    this.handleGetData ()
  },
  methods: {
    handleGetData () {
      if (!this.enterpriseID) return 
      axios.get(`https://www.dviewcontent.com/api/qrbannerhorizontal/?token=${this.token}&enterpriseID=${this.enterpriseID}&start_index=0&item_per_page=100&title_filter=&status=1`)
        .then(response => {
          this.count = response.data.total
          this.content = response.data.result
        })
    },
    handleSort(v) {
      //console.log(v)
      /*return
      
      const body = []
      const maxValue = Math.max.apply(null, v.map((o) => { return o.ORD }))
      for (let i = 0; i < v.length; i++)
        body.push ({ ID: v[i].ID, ORD: maxValue - i})
       
      api.put ('dviewcontent', `${this.contentTypeData.Type === 'meetingRoom' ? 'v1/' : ''}private/${router[this.contentTypeData.Type]}/${this.contentTypeData.ID}/sort`, body)          
        */
      let index = 1
      for (let i = v.length -1; i >= 0; i--) {
        let item = v[i]
        const ava = JSON.parse(item.Availability)
        const aux = ava.ORD
        ava.ORD = index
        console.log(item.Title, i, aux, ava.ORD)
        index++

        if (item.IsCommonContent) 
          axios.put(`https://www.dviewcontent.com/api/availability/${item.ID}/${item.EnterpriseID}/common?token=${this.token}`, ava)
            .then(response => console.log(response))
            .catch(error => console.log(error))
        else 
          axios.put(`https://www.dviewcontent.com/api/availability/${ava.ID}?token=${this.token}`, ava)
            .then(response => console.log(response))
            .catch(error => console.log(error))
      }
    },
  },
}
</script>

